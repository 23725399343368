import React from 'react';
import { Link } from 'react-router-dom';
import { FaAngleDown } from "react-icons/fa";
// import { headerData } from './data'


const Nav = () => {

    // return (
    //     <nav className="mainmenu-nav">
    //         <ul className="mainmenu">
    //             {headerData.length > 0 && headerData.map((headerItem) => {
    //                 console.log(headerData, headerItem)
    //                 return (
    //                     <li className="menu-item-has-children">
    //                         <Link to={process.env.PUBLIC_URL + `/${headerItem.path}`}>{headerItem.label}</Link>
    //                         {
    //                             headerItem && headerItem.children && headerItem.children.length > 0 &&
    //                             <ul className="axil-submenu">
    //                                 {
    //                                     headerItem.children.map((subHeaderItem) => {
    //                                         console.log('subHeaderItem', subHeaderItem)
    //                                         return (
    //                                             <li><Link to={process.env.PUBLIC_URL + `/${subHeaderItem.path}`}>{subHeaderItem.label}</Link></li>
    //                                         )
    //                                     })
    //                                 }
    //                             </ul>
    //                         }
    //                     </li>
    //                 )
    //             })}
    //         </ul>
    //     </nav>
    // )


    return (
        <nav className="mainmenu-nav">
            <ul className="mainmenu">
                <li>
                    <Link to={process.env.PUBLIC_URL + "/about-us"}>About Us </Link>
                </li>
                <li>
                    <Link to={process.env.PUBLIC_URL + "/services"}>Services</Link>
                </li>
                <li className="menu-item-has-children">
                    <Link to="#">Mulesoft <FaAngleDown /></Link>
                    <ul className="axil-submenu">
                        <li><Link to={process.env.PUBLIC_URL + "/mulesoft-consulting"}>Mulesoft Consulting</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/mulesoft-development"}>Mulesoft Development</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/mulesoft-migration"}>Mule-4 Migration</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/mule-b2b-integration"}>Mule B2B Integration</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/production-support"}>Production Support</Link></li>
                    </ul>
                </li>
                <li>
                    <Link to={process.env.PUBLIC_URL + "/team"}>Team</Link>
                </li>
                <li>
                    <Link to={process.env.PUBLIC_URL + "/blog"}>Blog</Link>
                </li>
                <li>
                    <Link to={process.env.PUBLIC_URL + "/careers"}>Careers</Link>
                </li>
                <li>
                    <Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link>
                </li>
            </ul>
        </nav>
    )
}

export default Nav;