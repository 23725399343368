import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ProcessOne from '../component/process/ProcessOne';
import AboutTwo from '../component/about/AboutTwo';
import SEO from '../common/SEO';

const FullstackDevelopment = () => {

    return (
        <>
        <SEO title="Fullstack Development" />
        <main className="main-wrapper">
            <HeaderOne />
            <BcrumbBannerOne 
            title="Full-stack Web Development"
            paragraph ="We are responsible for designing, developing, and maintaining all aspects of a web application, from the user interface to server-side logic and database."
            paragraph2 ="Our Full stack web development services provide a comprehensive solution for your web development needs. Our full stack web development team includes experts in front-end development, back-end development, database design, and system administration."
            styleClass=""
            mainThumb="/images/banner/fullstackDev.svg"
            />
            <AboutTwo />
           
            <ProcessOne />
            <CtaLayoutOne />
        <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default FullstackDevelopment;