import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ProcessOne from '../component/process/ProcessOne';
import AboutTwo from '../component/about/AboutTwo';
import SEO from '../common/SEO';

const MulesoftMigration = () => {

    return (
        <>
        <SEO title="Mulesoft Migration" />
        <main className="main-wrapper">
            <HeaderOne />
            <BcrumbBannerOne 
            title="Mulesoft Migration"
            paragraph ="We offer a reliable way to modernize your integration solutions for faster time-to-market, increased agility, and improved data connectivity."
            paragraph2 ="We provide a roadmap for the migration process, including detailed plans for data migration, application migration, and testing. Our experts ensure a seamless transition to Anypoint Platform, minimizing downtime and disruption to operations."
            styleClass=""
            mainThumb="/images/banner/muleMigration.svg"
            />
            <AboutTwo />
           
            <ProcessOne />
            <CtaLayoutOne />
        <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default MulesoftMigration;