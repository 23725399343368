import React from 'react';
// import Tilty from 'react-tilty';

const BcrumbBannerOne = ({title, paragraph, paragraph2, styleClass, mainThumb}) => {
    return (
        <div className="breadcrum-area breadcrumb-banner">
            <div className="container">
                <div className="section-heading heading-left">
                    <h1 className="title h2" dangerouslySetInnerHTML={{__html: title}}></h1>
                    <p dangerouslySetInnerHTML={{__html: paragraph}}></p>
                    <p className="subParagraph" dangerouslySetInnerHTML={{__html: paragraph2}}></p>
                </div>
                <div className={`banner-thumbnail ${styleClass}`}>
                    {/* <Tilty perspective={2000} reset={false}> */}
                        <img src={process.env.PUBLIC_URL + mainThumb} alt="Illustration" loading="lazy" />
                    {/* </Tilty> */}
                </div>
            </div>
            <ul className="shape-group-8 list-unstyled">
                <li className="shape shape-1">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-9.png"} alt="Bubble" loading="lazy" />
                </li>
                <li className="shape shape-2">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-21.png"} alt="Bubble" loading="lazy" />
                </li>
                <li className="shape shape-3">
                    <img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="Line" loading="lazy" />
                </li>
            </ul>
        </div>
    )
}

export default BcrumbBannerOne;