import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import Commentview from '../component/cta/Commentview';
import BlogData from "../data/blog/BlogData.json";
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import BlogSidebar from '../component/blog/BlogSidebar';
import BlogAuthor from '../component/blog/BlogAuthor';
// import Comment from '../component/blog/Comment';
// import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';
import { FaPlay, FaAngleRight, FaAngleLeft } from "react-icons/fa";
import FsLightbox from 'fslightbox-react';
import Slider from "react-slick";
import BlogListOne from '../component/blog/BlogListOne';
import axios from 'axios';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';




const allBlogData = BlogData;

const BlogDetails = () => {
    const params = useParams();
    const blogId = params.id;
    const [posts, setPosts] = useState(null);
    const [assetData, setAssetData] = useState([]); // Define assetData state
    
    useEffect(() => {
        

        axios
        // .get(`http://localhost/wordpress/wp-json/wp/v2/posts?slug=${blogId}&_embed=author,replies`)
        .get(`https://edit.dwinsoft.in/wp-json/wp/v2/posts?slug=${blogId}&_embed=author,replies`)
        
            .then((response) => {

                setPosts(response.data);
                setAssetData(assetData); // Set the assetData state here
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
            
            
    }, [blogId]);
    if (!posts) {
        // Add a loading state or message here
        return null;
    }
    
    
    const options = {
        renderNode: {
            'embedded-asset-block': node => {
                const assetId = node.data.target.sys.id;
                const asset = assetData.find(item => item.sys.id === assetId);
                console.log(asset)
                if (asset) {
                    const { title, description, file } = asset.fields;
                    const imageUrl = file.url;
                    const alt = description || title || 'Image';
                    // return <img src={imageUrl} alt={alt} />;
                    
                      // Extract width and height information from the content here
                      const width = file.details.image.width;
                      const height = file.details.image.height;

                return <img src={imageUrl} alt={alt} width={width} height={height} />;
                }

                return null;
            },
        },
    };
    
    return (
        <>
        
           {/* <p>{console.log(posts,'Finale Kiss')}</p>
           
           {posts.map((post, index) => (
           <img width={100} height={100} src={post.image_url} alt="Blog" loading="lazy" />
           ))} */}
         
            {posts.map((post, index) => (
            
                <div key={index}>
                    <SEO title={post.title.rendered} />
                    
            <main className="main-wrapper">
                    <HeaderOne />
                    <BreadCrumbOne 
                    title={post.title.rendered}
                    page="Blog"
                    />
                   
                  {/* <div>{console.log(post,'data')}</div> */}
                   <div className="section-padding-equal">
                    <div className="container">
                        <div className="row row-40">
                            <div className="col-lg-10">
                                <div className="single-blog">
                                    <div className="single-blog-content blog-grid">
                                     
                                        <div className="author">
                                            <div className="author-thumb">
                                            {console.log(post._embedded.author[0].avatar_urls[96])}
                                                <img src={`${post._embedded.author[0].avatar_urls[96]}`} alt="Blog Author" loading="lazy" />
                                            </div>
                                            <div className="info">
                                                <h6 className="author-name">{post._embedded.author[0].name}</h6>
                                                <ul className="blog-meta list-unstyled">
                                                    <li>{new Date(post.date).toLocaleDateString('en-US', { year: 'numeric',month: 'short',day: 'numeric' })}</li>
                                                </ul>
                                            </div>
                                        </div>
                                        
                                        <div>
                                            {/* {documentToReactComponents(post.contentBody, options)} */}
                                            
                                            <div dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
                                        </div>
                                       
                                        
                                        

                                        <div className="row">
                                            
                                        {/* <img width={100} height={100} src={post.image_url} alt="Blog" loading="lazy" /> */}
                                            
                                            
                                        </div>
                                    </div>
                                </div>
                                <BlogAuthor data={post} />

                            </div>
                           
                        </div>
                        <div className="row row-40">
                        
                          
                                <BlogSidebar />
                           
                           
                        </div>
                        
                    </div>
                    </div>
                   

                   
                    {/* <Commentview   replies={post._embedded.replies}/> */}
                    <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
                    
                    
            
                   
                </div>
            ))}
        </>
    );
}

export default BlogDetails;