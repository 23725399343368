import React, {useRef, useState} from 'react';
import { Formik, Form, Field } from 'formik';
import { TextareaAutosize } from '@mui/material';
import * as Yup from 'yup';
import emailjs from 'emailjs-com';


const FormTwo = () => {

    const [formSubmitted, setFormSubmitted] = useState(false);
    const formRef = useRef();

    const initialValues = {
        contactName:'',
        contactEmail:'',
        contactPhone:'',
        contactMessage:''
    }
    
    const form = useRef();


    const demo = async (formRef, props) => {
        try {
            
             // Initialize EmailJS using the user_id from your EmailJS account
      emailjs.init('cGYAQoxYiW4Ii4Nh8');
          // Send email using EmailJS
          const result = await emailjs.send(
            'service_2ukb7pp',
            'template_gqmldud',
            formRef.current
          );
    
          if (result.status === 200) {
            console.log('Email sent successfully');
            setFormSubmitted(true);
            formRef.current.reset();
          } else {
            console.error('Failed to send email');
          }
        } catch (error) {
          console.error('An error occurred', error);
        }
      };
      
      
     
    
    const sendEmail = (values, { setSubmitting }) => {
        emailjs.sendForm('service_2ukb7pp', 'template_gqmldud', formRef.current, 'cGYAQoxYiW4Ii4Nh8')
            .then((result) => {
                console.log(result.text);
                alert('Your message has been sent.')
                setSubmitting(false);
                setFormSubmitted(true);
                formRef.current.reset(); // Reset the form
            })
            .catch((error) => {
                console.log(error.text);
                alert('Try again later');
                setSubmitting(false);
            });
    };

    const validationSchema= Yup.object().shape({
        contactName: Yup.string().required("Required").matches(/^[a-zA-Z\\.\s]{3,}$/,"Invalid name"),
        contactEmail: Yup.string().required("Required").matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,4}$/,"Invalid email address"),
        contactPhone: Yup.string().required("Required").matches(/^(0|91)?[6-9][0-9]{9}$/,"Invalid phone number"),
        contactMessage: Yup.string().matches(/^[a-zA-Z0-9\\.\s,]{15,}$/,"Enter atleast 15 characters")
    })

    return (
        // <Formik initialValues={initialValues} onSubmit={sendEmail} validationSchema={validationSchema} validateOnChange={true} validateOnBlur={true}>
          <Formik initialValues={initialValues} onSubmit={sendEmail} validationSchema={validationSchema} validateOnChange={true} validateOnBlur={true}>

        {({ errors, touched }) => (
                <Form className="axil-contact-form" ref={formRef}>
                <div className="form-group">
                    <label>Name</label>
                    <Field type="text" name="contactName" autocomplete="off" id="form-control"
                            className={errors.contactName ? 'error-control' : ''}
                        />
                        {touched.contactName && errors.contactName && (
                            <div className="nameError">{errors.contactName}</div>
                        )}
                </div>
                <div className="form-group">
                    <label>Email</label>
                    <Field type="email" name="contactEmail" autocomplete="off" id="form-control"
                            className={errors.contactEmail ? 'error-control' : ''}
                        />
                        {touched.contactEmail && errors.contactEmail && (
                            <div className="nameError">{errors.contactEmail}</div>
                        )}
                </div>
                <div className="form-group">
                    <label>Phone</label>
                    <Field type="tel" name="contactPhone" autocomplete="off" id="form-control"
                            className={errors.contactPhone ? 'error-control' : ''}
                        />
                        {touched.contactPhone && errors.contactPhone && (
                            <div className="nameError">{errors.contactPhone}</div>
                        )}
                </div>
                <div className="form-group mb--40">
                    <label>How can we help you?s</label>
                    <Field as={TextareaAutosize} name="contactMessage" id="form-control" rows="5"
                            className={errors.contactMessage ? 'error-control' : ''}
                    />
                    {touched.contactMessage && errors.contactMessage && (
                        <div className="nameError">{errors.contactMessage}</div>
                    )}
                </div>
                <div className="form-group">
                    <button type="submit" className="axil-btn btn-fill-primary btn-fluid btn-primary" name="submit-btn" >Send Notification</button>
                    {
                        formSubmitted && (
                            <div className="success-msg"></div>
                        )
                    }
                </div>
            </Form>
            )}
    </Formik>
    )
}

export default FormTwo;