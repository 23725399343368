import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowLeft, FaArrowRight,FaSearch } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';
import axios from 'axios';

// Function to truncate HTML content while preserving tags
function truncateHtml(text, maxLength) {
    const truncated = text.replace(/<[^>]*>?/gm, ''); // Remove HTML tags
    return truncated.length > maxLength ? truncated.slice(0, maxLength) + '...' : truncated;
  }
  
const BlogGridTwo = (categoryId) => {

    const [posts, setPosts] = useState([]);
    // const [categoryName, setCategory] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);

    const blogsPerPage = 12;
    const pageVisited = pageNumber * blogsPerPage;
    
    
    const ID = categoryId;
    
    // useEffect(() => {

    //     axios
    //         .get(
    //             `http://localhost/wordpress/wp-json/wp/v2/categories/${ID.categoryId}`
    //         )
    //         .then((responseCategoryId) => {
                
    //             console.log(responseCategoryId,'fucking')
    //             const  categoryName= responseCategoryId.data.name;
               
    //             setCategory(categoryName);
    //         })
    //         .catch((error) => {
    //             console.error('Error fetching data:', error);
    //         });
    // }, []);


    useEffect(() => {

        axios
            .get(
                // `http://localhost/wordpress/wp-json/wp/v2/posts?categories=${ID.categoryId}`
                `https://edit.dwinsoft.in/wp-json/wp/v2/posts?categories=${ID.categoryId}`
            )
            .then((response) => {
                
                const posts = response.data;
               
                setPosts(posts);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };


    return (
        <>
         {/* <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', maxWidth: '1800px', margin: '2rem auto', gap: '2rem'}} >
            <h3>{categoryName}</h3>
         </div> */}

            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', maxWidth: '1800px', margin: '2rem auto', gap: '2rem'}} >
                {/* {posts.map((post) => ( */}
                {posts.slice(pageVisited, pageVisited + blogsPerPage).map((post) => (
           
                    <div className='cardHover' style={{background: '', display: 'flex', flexDirection: 'column', width: 'clamp(20rem, calc(20rem + 2vw), 22rem)', overflow: 'hidden', boxShadow: '0 .1rem 1rem rgba(0, 0, 0, 0.1)', borderRadius: '1em',  transition: 'border-bottom 0.3s',borderBottom: '6px solid transparent',}}  onMouseEnter={(e) => {
                        e.currentTarget.style.borderBottom = '6px solid green'; // Apply red border on hover
                    }}
                    onMouseLeave={(e) => {
                        e.currentTarget.style.borderBottom = '6px solid transparent'; // Reset border when not hovered
                    }}>
                            
                            
                        <div  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', transition: 'box-shadow 0.3s' }}    >
                           
                            <div className="post-thumbnail" style={{ overflow: 'hidden', width: '340px', height: '220px' , borderRadius: '8px'}}>
                            <Link to={process.env.PUBLIC_URL + `/blog-details/${post.slug}`}>
                                <img width="350" height="350" src={post.image_url} alt={post.title.rendered} style={{ maxWidth: '100%' }} loading="lazy" />
                            </Link>
                           </div> 
                        </div>
                        <div style={{ padding: '1rem', display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
                          <span>{new Date(post.date).toLocaleDateString('en-US', { year: 'numeric',month: 'short',day: 'numeric' })}</span>
                            <h6 className="title">
                                <Link to={process.env.PUBLIC_URL + `/blog-details/${post.slug}`}>{post.title.rendered}</Link>
                            </h6>
                            {/* <p>{extractFirstParagraph(post.contentBody.content,100)}. . .</p> */}
                         
                            {/* <div dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} /> */}
                            <div dangerouslySetInnerHTML={{ __html: truncateHtml(post.excerpt.rendered, 100) }} />
                          
                            <div style={{ display: 'flex', marginTop: 'auto',}}>
                            <Link style={{width:'50' }} className="axil-btn btn-borderd" to={process.env.PUBLIC_URL + `/blog-details/${post.slug}`}>Read More</Link>
                            </div>
                        </div>
                       
                      
                    </div>

                ))}
            </div>


        
            
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  <ReactPaginate
    previousLabel={<FaArrowLeft />}
    nextLabel={<FaArrowRight />}
    pageCount={Math.ceil(posts.length / blogsPerPage)}
    onPageChange={changePage}
    containerClassName={'pagination'}
    previousLinkClassName={'prev'}
    nextLinkClassName={'next'}
    disabledClassName={'disabled'}
    activeClassName={'current'}
  />
</div>
            
           
        </>
    );
};


export default BlogGridTwo;