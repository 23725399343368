import React from 'react';
import FormOne from '../contact/FormOne';
import Accordion from 'react-bootstrap/Accordion';
import { FaCode, FaCogs, FaHandsHelping, FaRocket, FaSmileBeam } from 'react-icons/fa';


const AboutTwo = () => {
    return (
        <div className="section-padding">
            <div className="container">
            <div className="row">
                    <div className="col-lg-6">
                        <div className="why-choose-us">
                            <div className="section-heading heading-left">
                                <span className="subtitle">About Us</span>
                                <h3 className="title">Why choose us?</h3>
                                <p>Choosing us means choosing a trusted partner that is committed to delivering exceptional results for your business.</p>
                            </div>
                            <Accordion defaultActiveKey="1">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header><FaCode /> Expertise</Accordion.Header>
                                    <Accordion.Body>
                                    Our team consists of experienced professionals who are experts in their respective fields, ensuring that you receive high-quality service and solutions.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header><FaCogs /> Customization</Accordion.Header>
                                    <Accordion.Body>
                                    We understand that every organization has unique needs, and we work closely with our clients to provide customized solutions that meet their specific requirements.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header><FaHandsHelping /> Collaboration</Accordion.Header>
                                    <Accordion.Body>
                                    We believe in working collaboratively with our clients, building strong partnerships to achieve their goals.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header><FaRocket /> Agility</Accordion.Header>
                                    <Accordion.Body>
                                    Our team is agile and adaptable, able to respond quickly to changing business needs and technological advancements.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header><FaSmileBeam /> Customer satisfaction</Accordion.Header>
                                    <Accordion.Body>
                                    Our focus is on ensuring our clients are satisfied with the services we provide. We strive to exceed expectations and deliver value to our clients.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6 offset-xl-1">
                        <div className="contact-form-box shadow-box mb--30">
                            <h3 className="title">Connect with us</h3>
                            <FormOne />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutTwo;