import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ProcessOne from '../component/process/ProcessOne';
import AboutTwo from '../component/about/AboutTwo';
import SEO from '../common/SEO';

const Snaplogic = () => {

    return (
        <>
        <SEO title="Snaplogic" />
        <main className="main-wrapper">
            <HeaderOne />
            <BcrumbBannerOne 
            title="Snaplogic"
            paragraph ="With Snaplogic's cloud-based integration platform we connect applications and data sources across the enterprise to enrich your business standards."
            paragraph2 ="SnapLogic offers a wide range of pre-built connectors for popular applications, such as Salesforce, Workday, and ServiceNow, as well as connectors for on-premise applications, such as SAP and Oracle. The platform also includes a data integration tool that allows users to create data pipelines for moving, transforming, and enriching data."
            styleClass=""
            mainThumb="/images/banner/snaplogicService.svg"
            />
            <AboutTwo />
           
            <ProcessOne />
            <CtaLayoutOne />
        <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default Snaplogic;