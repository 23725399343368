// import React from 'react';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import BlogData from "../../../data/blog/BlogData.json";
import axios from 'axios';


// const allBlogData = BlogData;


const WidgetPost = () => {
    
    
    const [posts, setPosts] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);

    // const blogsPerPage = 10;
    // const pageVisited = pageNumber * blogsPerPage;

    useEffect(() => {

        axios
            .get(
                `https://edit.dwinsoft.in/wp-json/wp/v2/posts`
                // `http://localhost/wordpress/wp-json/wp/v2/posts`
            )
            .then((response) => {
                
                const posts = response.data;

             
               
                setPosts(posts);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };
    
  
    const extractFirstParagraph = (contentArray, maxLength) => {
        if (contentArray && contentArray.length > 0) {
            const paragraphObject = contentArray.find(item => item.nodeType === 'paragraph');
            if (paragraphObject) {
                const firstParagraphValue = paragraphObject.content[0]?.value;
                const truncatedValue = firstParagraphValue?.substring(0, maxLength);
                return truncatedValue || "No content found.";
            } else {
                return null;
            }
        } else {
            return null;
        }
    };
    
  
    const allBlogData = posts;
    
    
    
    return (
        <div className="post-list-wrap">
        
        {allBlogData.slice(0, 4).map((data, index) => (
            <div className="single-post" key={index}>
                <div className="post-thumbnail">
                    
                
                    <Link to={process.env.PUBLIC_URL + `/blog-details/${data.slug}`}>
                        <img  src={data.image_url} alt="Blog" loading="lazy" />
                    </Link>
                </div>
                <div className="post-content">
                    <h6 className="title">
                    <Link to={process.env.PUBLIC_URL + `/blog-details/${data.slug}`}>
                        {data.title.rendered}
                    </Link>
                    </h6>
                    <ul className="blog-meta list-unstyled">
                        <li>{new Date(data.date).toLocaleDateString('en-US', { year: 'numeric',month: 'short',day: 'numeric' })}</li>
                        {/* <li>{data.read_time}</li> */}
                    </ul>
                </div>
            </div>
        ))}
    </div>
    )
}

export default WidgetPost;


