import React, { useRef } from 'react';
import SEO from '../common/SEO';
// import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import { Link } from 'react-router-dom';
import { FaEnvelopeOpen } from "react-icons/fa";
// import Countdown from 'react-countdown';
import SplashFooter from '../common/footer/SplashFooter';
// import Alert from 'react-bootstrap/Alert';

// const Result = () => {
//     return (
//         <Alert variant="success" className="success-msg">
//             Your Message has been successfully sent.
//         </Alert>
//     )
// }

const ComingSoon = () => {

    const emailId = useRef(null);

    function notifyMeApi(){
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        
        var raw = JSON.stringify({
        "email": emailId.current.value
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch("http://dwinsoft-comingsoon-api.us-e2.cloudhub.io/notify", requestOptions)
        .then(response => response.json())
        .then(response => console.log(JSON.stringify(response)))
        .catch(error => console.log('error', error));
    }
    // const Completionist = () => <span></span>;
    // const SetCountdownTime = "2023-01-10T17:00:00";
    // const CountDownRender = ({ days, hours, minutes, seconds, completed }) => {
    //     if (completed) {
    //       return <Completionist />; 
    //     } else {
    //       return (
    //           <div className="countdown">
    //               <div className="countdown-section">
    //                 <span className="countdown-number">{days}</span>
    //                 <span className="countdown-unit">Days</span>
    //               </div>
    //               <div className="countdown-section">
    //                 <span className="countdown-number">{hours}</span>
    //                 <span className="countdown-unit">Hours</span>
    //               </div>
    //               <div className="countdown-section">
    //                 <span className="countdown-number">{minutes}</span>
    //                 <span className="countdown-unit">Hours</span>
    //               </div>
    //               <div className="countdown-section">
    //                 <span className="countdown-number">{seconds}</span>
    //                 <span className="countdown-unit">Hours</span>
    //               </div>
    //           </div>
    //       )
    //     }
    //   }

    return (
        <>
        <SEO title="Home" />
        {/* <ColorSwitcher /> */}
            <main className="main-wrapper">
                <div className="coming-soon-area onepage-screen-area">
                    <div className="container">
                    <div className="col-12">
                            <div className="site-logo">
                                <Link to={process.env.PUBLIC_URL + "/"} className="logo-light"><img src={process.env.PUBLIC_URL + "/images/dwinsoftLogo_dark.png"} alt="Logo" loading="lazy" /></Link>
                                <Link to={process.env.PUBLIC_URL + "/"} className="logo-dark"><img src={process.env.PUBLIC_URL + "/images/dwinsoftLogo_dark.png"} alt="Logo" loading="lazy" /></Link>
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-center">
                        <div className="col-xl-6 col-lg-8">
                            <div className="content">
                            <h2 className="title">Our new website is on its way</h2>
                            <p>Client satisfaction is our top priority and so, we never compromise on quality.</p>
                            <p>We at Dwinsoft are always ready to hear you. Send us your queries at info@dwinsoft.in and stay updated.</p>
                            <p>17/14/2, Kannampalikadu, Thiruchengode Road, Sankari, Salem - 637301.    Contact: +91 7708443455, +91 9489320805</p>
                            {/* <Countdown date={SetCountdownTime} zeroPadTime={3} renderer={CountDownRender}></Countdown> */}
                            <form>
                                <div className="input-group">
                                    <span className="mail-icon"><FaEnvelopeOpen /> </span>
                                    <input type="email" className="form-control" placeholder="Your email address" ref={emailId}/>
                                    <button className="subscribe-btn" type="submit" onClick={notifyMeApi}>Notify Me</button>
                                </div>
                                {/* <div className="form-group">
                                    {response ? <Result /> : null}
                                </div> */}
                            </form>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="thumbnail">
                                <img src={process.env.PUBLIC_URL + "/images/teamWork.png"} alt="Coming Soon" loading="lazy" />
                            </div>
                        </div>
                        </div>
                    </div>
                    <ul className="shape-group-8 list-unstyled">
                        <li className="shape shape-2">
                            <img src={process.env.PUBLIC_URL + "/images/others/bubble-28.png"} alt="Bubble" loading="lazy" />
                        </li>
                        <li className="shape shape-3">
                            <img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="Line" loading="lazy" />
                        </li>
                    </ul>
                </div>
                <SplashFooter />
            </main>
        </>
    )
}

export default ComingSoon;