import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ProcessOne from '../component/process/ProcessOne';
import AboutTwo from '../component/about/AboutTwo';
import SEO from '../common/SEO';

const PerformanceTesting = () => {

    return (
        <>
        <SEO title="Performance Testing" />
        <main className="main-wrapper">
            <HeaderOne />
            <BcrumbBannerOne 
            title="Performance Testing"
            paragraph ="Our testing service provide valuable insights into the performance characteristics of a software application and help to ensure that it meets the required performance standards."
            paragraph2 ="We simulate realistic workload conditions and measure the application's performance metrics. These metrics include response time, throughput, resource utilization, and error rates."
            styleClass=""
            mainThumb="/images/banner/performanceTesting.svg"
            />
            <AboutTwo />
           
            <ProcessOne />
            <CtaLayoutOne />
        <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default PerformanceTesting;