import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import Tab from '../common/header/Tab';
import HeaderOne from '../common/header/HeaderOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import BlogSidebar from '../component/blog/BlogSidebar';
import BlogGridOne from '../component/blog/BlogGridOne';
// import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';


const BlogGridCategory = () => {

    return (
        <>
            <SEO title="Blog Grid" />
            {/* <ColorSwitcher /> */}
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne 
                title=""
                page="Blogs + Demos"
                />
                <div className="section-padding-equal">
                    {/* <div className="container"> */}
                    {/* <Tab /> */}
                    
                    <BlogGridOne />
                       <div className="row">
                       {/* <BlogGridOne /> */}
                            {/* <div className="col-lg-8">
                            <BlogGridOne />
                            </div>
                            <div className="col-lg-4">
                            <BlogSidebar />
                            </div> */}
                       </div>
                    {/* </div> */}
                </div>
                <CtaLayoutOne />
            <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default BlogGridCategory;