import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
// import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import CaseStudyProp from '../component/casestudy/CaseStudyProp';


const CaseStudy = () => {

    return (
        <>
        <SEO title="Careers" />
        {/* <ColorSwitcher /> */}
            <main className="main-wrapper">
                <HeaderOne />
                <BcrumbBannerOne 
                title="Careers"
                paragraph ="Explore your potential and unlock your dream career with us. Join our dynamic team for exciting opportunities and build a fulfilling career with us."
                paragraph2 ="We are a vibrant and growing company that values creativity, innovation, and teamwork. We are always looking for talented individuals who share our passion for excellence and our commitment to making a positive impact in the world."
                styleClass="thumbnail-3"
                mainThumb="/images/banner/careersThumb.svg"
                />
                <div className="pt--250 pt_lg--200 pt_md--100 pt_sm--80 case-study-page-area">
                    <div className="container">
                        <CaseStudyProp />
                    </div>
                </div>
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default CaseStudy;