import React, {useState, useRef} from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaLinkedin, FaInstagram, FaEnvelopeOpen } from "react-icons/fa";
import ServiceData from "../../data/service/ServiceMain.json";
import { slugify } from '../../utils';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

const getServiceData = ServiceData;

const FooterOne = ({parentClass}) => {

    const [formSubmitted, setFormSubmitted] = useState(false);
    const formRef = useRef();

    const initialValues = {
        contactEmail:''
    }

    const onSubmit=(values,props)=>{ 
        console.log(values) 
        setFormSubmitted(true);  
        formRef.current.reset();
    }

    const validationSchema = Yup.object().shape({
        contactEmail: Yup.string().matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,4}$/,"Invalid email address").required("Required")
    })

    return (
        <footer className={`footer-area ${parentClass}`}>
            <div className="container">
                <div className="footer-top">
                    <div className="footer-social-link">
                        <ul className="list-unstyled">
                            <li><a href="https://www.linkedin.com/company/dwinsoft-technologies-india-private-limited/" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a></li>
                            <li><Link to="https://facebook.com/"><FaFacebookF /></Link></li>
                            <li><Link to="https://twitter.com/"><FaTwitter /></Link></li>
                            <li><Link to="https://www.instagram.com/"><FaInstagram /></Link></li>
                        </ul>
                    </div>
                </div>
                <div className="footer-main">
                    <div className="row">
                        <div className="col-xl-6 col-lg-5">
                            <div className="footer-widget border-end">
                                <div className="footer-newsletter">
                                    <h2 className="title">Get in touch!</h2>
                                    <p>We at Dwinsoft are always ready to hear you. Send your queries to info@dwinsoft.in and stay updated.</p>
                                    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} validateOnChange={true} validateOnBlur={true}>
                                        {({ errors, touched }) => (
                                            <Form ref={formRef}>
                                                <div className="input-group">
                                                    <span className="mail-icon"><FaEnvelopeOpen /> </span>
                                                    <Field type="email" name="contactEmail" className="form-control" placeholder="Email address" autocomplete="off"/>
                                                    <button className="subscribe-btn" type="submit" disabled={errors.contactEmail}>Submit</button>
                                                    {touched.contactEmail && errors.contactEmail && (
                                                        <div className="footerMailError">{errors.contactEmail}</div>
                                                    )}
                                                    {formSubmitted && (
                                                        <div className="success-msg"><p>Your message has been sent.</p></div>
                                                    )}
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-7">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Services</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                {getServiceData.slice(0, 5).map((data, index) => (
                                                    <li key={index}>
                                                        <Link to={process.env.PUBLIC_URL + `/${slugify(data.path)}`}>
                                                        {/* <Link to={process.env.PUBLIC_URL + `/`}> */}
                                                            {data.title}
                                                            </Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Resources</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                <li><Link to={process.env.PUBLIC_URL + "/blog"}>Blog</Link></li>
                                                <li> <Link to={process.env.PUBLIC_URL + "/careers"}>Careers</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Support</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                <li><Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link></li>
                                                <li> <Link to={process.env.PUBLIC_URL + "/privacy-policy"}>Privacy Policy</Link></li>
                                                <li><Link to={process.env.PUBLIC_URL + "/terms-use"}>Terms of Use</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom"> 
                    <div className="row">
                        <div className="col-md-6">
                            <div className="footer-copyright">
                                <span className="copyright-text">© {new Date().getFullYear()}. All rights reserved by Dwinsoft Technologies India Pvt Ltd.
                                {/* <a href="https:info@dwinsoft.in">Dwinsoft Technologies India Pvt Ltd</a> */}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterOne;