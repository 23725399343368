import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BlogGridTwo from '../component/blog/BlogGridTwo';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BlogData from "../data/blog/BlogData.json";
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
// import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import {slugify} from '../utils';
import axios from 'axios';


const allBlogData = BlogData;

const BlogCategory = () => {

    const params = useParams();
    const blogCategory = params.slug;
    const ID = blogCategory;
    const [categoryName, setCategory] = useState();
    
    

     
    useEffect(() => {

        axios
            .get(
                // `http://localhost/wordpress/wp-json/wp/v2/categories/${ID}`
                `https://edit.dwinsoft.in/wp-json/wp/v2/categories/${ID}`
            )
            .then((responseCategoryId) => {
                const  categoryName= responseCategoryId.data.name;
                setCategory(categoryName);
                console.log(categoryName)
                
                
           })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);




    return (
        <>
        <SEO title="Category" />
        {/* <ColorSwitcher /> */}
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne 
                title={`Category - ${categoryName}`}
                // page="Blog"
                page="Blog"
                />
                <div className="section-padding-equal">
                    <div className="container">
                        <div className="row">
                            <BlogGridTwo categoryId={blogCategory} colSize="col-lg-4"/>
                        </div>
                    </div>
                </div>
                <CtaLayoutOne />
            <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default BlogCategory;