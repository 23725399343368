import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ProcessOne from '../component/process/ProcessOne';
import AboutTwo from '../component/about/AboutTwo';
import SEO from '../common/SEO';

const MulesoftConsulting = () => {

    return (
        <>
        <SEO title="Mulesoft Consulting" />
        <main className="main-wrapper">
            <HeaderOne />
            <BcrumbBannerOne 
            title="Mulesoft Consulting"
            paragraph ="Our MuleSoft Consulting services help to design, build, and implement effective API-led connectivity solutions using the MuleSoft tools. We unlock the power of integration with our expert consultance."
            paragraph2 ="Our services include Strategy and Roadmap, Architecture and Design, Implementation and Integration, Optimisation and Support. These can help organizations streamline their technology infrastructure, improve operational efficiency and enhance customer experiences."
            styleClass=""
            mainThumb="/images/banner/muleConsulting.svg"
            />
            <AboutTwo />
           
            <ProcessOne />
            <CtaLayoutOne />
        <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default MulesoftConsulting;