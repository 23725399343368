import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const CategoryDetails = () => {
    const params = useParams();
    const blogId = params.id;
    const [blogData, setBlogData] = useState(null);

    useEffect(() => {
        const spaceId = 'ohmgmknah5e4';
        const accessToken = 'Wc9Q9N-q9_vk3Nr5SlejCJjP9CC_lNDKM0o4u_fXukw';
        const content_type = 'dwinsoftBlog';

        axios
            .get(`https://cdn.contentful.com/spaces/${spaceId}/environments/master/entries?access_token=${accessToken}&content_type=${content_type}&sys.id=${blogId}`)
            .then((response) => {
                const data = response.data.items;
                // Assuming you want to display the first item's 'fields' data
                if (data.length > 0) {
                    setBlogData(data[0].fields);
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, [blogId]);

    return (
        <>
            <h2>Done</h2>
            
        </>
    );
};

export default CategoryDetails;