import React from 'react';
import { Link } from 'react-router-dom';
import WidgetPost from './widget/WidgetPost';
import WidgetCategory from './widget/WidgetCategory';
import { FaSearch, FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { Margin } from '@mui/icons-material';

const BlogSidebar = () => {
    return (
        
        <div className="col-lg-10">
            <div className="axil-sidebar">
           
           <div className='row'>
            
           <div className="col-lg-8">
                
                <div className="widget widget-recent-post">
                    <h4 className="widget-title">Recent posts</h4>
                    <WidgetPost />
                </div>
               
             </div>
             <div className="col-lg-4">
                <div className="widget widget-categories">
                    <h4 className="widget-title">Categories</h4>
                    <WidgetCategory />
                </div>
            </div>
           </div>
            
            </div>
            <div className="widget widge-social-share" style={{ marginTop: '80px' }}>
                    <div className="blog-share">
                        <h5 className="title">Follow:</h5>
                        <ul className="social-list list-unstyled">
                            <li><a href="https://facebook.com/" target="_blank" rel="noopener noreferrer"><FaFacebookF /></a></li>
                            <li><a href="https://twitter.com/" target="_blank" rel="noopener noreferrer"><FaTwitter /></a></li>
                            <li><a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a></li>
                            <li><a href="https://www.linkedin.com/company/dwinsoft-technologies-india-private-limited/" target="_blank" rel="noopener noreferrer"><FaLinkedinIn /></a></li>
                        
                        </ul>
                    </div>
                </div>
        </div>
        
    )
}

export default BlogSidebar;