import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ProcessOne from '../component/process/ProcessOne';
import AboutTwo from '../component/about/AboutTwo';
import SEO from '../common/SEO';

const MulesoftDevelopment = () => {

    return (
        <>
        <SEO title="Mulesoft Development" />
        <main className="main-wrapper">
            <HeaderOne />
            <BcrumbBannerOne 
            title="Mulesoft Development"
            paragraph ="Our MuleSoft development aids in creating APIs quickly, improving the speed of integration and reducing development costs. Our team develops the best and optimised application ensuring reliability."
            paragraph2 ="Our services include Designing the application, Building the API, Testing and Debugging, and Deployment. We create API-led connectivity solutions that integrate applications, data, and devices seamlessly."
            styleClass=""
            mainThumb="/images/banner/muleDevelopment.svg"
            />
            <AboutTwo />
           
            <ProcessOne />
            <CtaLayoutOne />
        <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default MulesoftDevelopment;