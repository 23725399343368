import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';

const Datas = [
    {
        id: 1,
        title: "Innovative and Creative Environment",
        para: "We foster an innovative and creative environment that encourages our employees to think outside the box and bring new ideas to the table. We believe that everyone has something valuable to contribute, and we empower our team to express their creativity and explore new possibilities."
    },
    {
        id: 2,
        title: "Cutting-Edge Technology",
        para: "We are committed to using the latest and most advanced technology in our work, which means that you will have access to the latest tools and platforms to deliver your best work."
    },
    {
        id: 3,
        title: "Professional Growth Opportunities",
        para: "We value the growth and development of our employees and provide opportunities for them to expand their skills and knowledge. We offer regular training programs, mentorship opportunities, and ongoing support to help you reach your full potential."
    },
    {
        id: 4,
        title: "Collaborative Culture",
        para: "We believe that great work comes from great collaboration, which is why we encourage a culture of teamwork and collaboration. Our team works together closely, sharing ideas and knowledge, to achieve the best results."
    },
    {
        id: 5,
        title: "Impactful Work",
        para: "We are passionate about making a positive impact on the world through our work. Our projects are purpose-driven and focused on solving real-world problems, which means that you will be working on meaningful and impactful projects."
    },
    {
        id: 6,
        title: "Work-life balance",
        para: "We understand that our employees have lives outside of work, and we strive to create a work environment that promotes work-life balance. We offer flexible work arrangements, including remote work options, to help you achieve a healthy work-life balance."
    }
]




const AboutThree = () => {
    return (
        <div className="section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20">
            <div className="container">
                <SectionTitle 
                    subtitle="Our Values"
                    title="Why should you work with us?"
                    description="We believe that working with us is not just a job, but a journey of growth, learning, and making a positive impact. If you are passionate about technology and innovation, and looking for a challenging and rewarding career, we would love to hear from you."
                    textAlignment="heading-left heading-light-left mb--100"
                    textColor=""
                />

                <div className="row">
                    {Datas.map((data) => (
                        <div className="col-lg-4" key={data.id}>
                            <div className="about-quality">
                                <h3 className="sl-number">{data.id}</h3>
                                <h5 className="title">{data.title}</h5>
                                <p>{data.para}</p>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
            <ul className="list-unstyled shape-group-10">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-30.png"} alt="Circle" loading="lazy" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-42.png"} alt="Circle" loading="lazy" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/line-7.png"} alt="Circle" loading="lazy" /></li>
            </ul>
        </div>
    )
}

export default AboutThree;