import React from 'react';
import { FaLinkedin } from "react-icons/fa";

const BlogAuthor = ({data}) => {
    return (
        <div className="blog-author">
            <div className="author">
                <div className="author-thumb">
                    <img src={`${data._embedded.author[0].avatar_urls[96]}`} alt="Blog Author" loading="lazy" />
                </div>
                <div className="info">
                    <h5 className="title">{data._embedded.author[0].name}</h5>
                    <p>{data._embedded.author[0].description}</p>
                    <ul className="social-share list-unstyled">
                        
                        <li>{data.posting}</li>
                        {/* <li><a href={data.social[0].facebook}><FaFacebookF /></a></li>
                        <li><a href={data.social[0].twitter}><FaTwitter /></a></li> */}
                        <li><a href={data._embedded.author[0].url} target="_blank" rel="noopener noreferrer"><FaLinkedin /></a></li>
                        {/* <li><a href={data.social[0].instagram}><FaInstagram /></a></li> */}
                        {/* <li><a href={data.social[0].vimeo}><FaVimeoV /></a></li>
                        <li><a href={data.social[0].pinterst}><FaPinterestP /></a></li>
                        <li><a href={data.social[0].dribble}><FaDribbble /></a></li>
                        <li><a href={data.social[0].behance}><FaBehance /></a></li> */}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default BlogAuthor;