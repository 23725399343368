import React, {useRef, useState} from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

const FormOne = () => {

    const [formSubmitted, setFormSubmitted] = useState(false);
    const formRef = useRef();

    const initialValues = {
        contactName:'',
        contactEmail:'',
        contactPhone:''
    }

    const onSubmit=(values,props)=>{ 
        console.log(values);
        setFormSubmitted(true);  
        formRef.current.reset();
    }

    const validationSchema= Yup.object().shape({
        contactName: Yup.string().required("Required").matches(/^[a-zA-Z\\.\s]{3,}$/,"Invalid name"),
        contactEmail: Yup.string().required("Required").matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,4}$/,"Invalid email address"),
        contactPhone: Yup.string().required("Required").matches(/^(0|91)?[6-9][0-9]{9}$/,"Invalid phone number")
    })

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} validateOnChange={true} validateOnBlur={true}>
            {({ errors, touched }) => (
                <Form className="axil-contact-form" ref={formRef}>
                <div className="form-group">
                    <label>Name</label>
                    <Field type="text" name="contactName" autocomplete="off" id="form-control"
                            className={errors.contactName ? 'error-control' : ''}
                        />
                        {touched.contactName && errors.contactName && (
                            <div className="nameError">{errors.contactName}</div>
                        )}
                </div>
                <div className="form-group">
                    <label>Email</label>
                    <Field type="email" name="contactEmail" autocomplete="off" id="form-control"
                            className={errors.contactEmail ? 'error-control' : ''}
                        />
                        {touched.contactEmail && errors.contactEmail && (
                            <div className="nameError">{errors.contactEmail}</div>
                        )}
                </div>
                <div className="form-group">
                    <label>Phone</label>
                    <Field type="tel" name="contactPhone" autocomplete="off" id="form-control"
                            className={errors.contactPhone ? 'error-control' : ''}
                        />
                        {touched.contactPhone && errors.contactPhone && (
                            <div className="nameError">{errors.contactPhone}</div>
                        )}
                </div>
                <div className="form-group">
                    <button type="submit" className="axil-btn btn-fill-primary btn-fluid btn-primary" name="submit-btn" >Contact Us</button>
                    {
                        formSubmitted && (
                            <div className="success-msgFormOne"><p>Your message has been sent.</p></div>
                        )
                    }
                </div>
            </Form>
            )}
    </Formik>
    )
}

export default FormOne;