import React from 'react';
import { Link } from 'react-router-dom';
import TeamOne from '../team/TeamOne';


const AboutFive = () => {
    return (
      <div className="section-padding-equal">
            <div className="container">
                <div className="row align-items-center">
                <div className="col-lg-7">
                    <div className="about-team">
                    <div className="thumbnail">
                        <img src={process.env.PUBLIC_URL + "/images/about/teamEffort.svg"} alt="thumbnail" loading="lazy" />
                    </div>
                    </div>
                </div>
                <div className="col-lg-5">
                    <div className="about-team">
                    <div className="section-heading heading-left">
                        <span className="subtitle">Our Team</span>
                        <h2>Success of a business depends on the quality of its people</h2>  
                        <p>Stronger Together: Harnessing the power of Teamwork to achieve Success and drive Innovation. Collaboration makes novelty possible.</p>
                        <Link to={process.env.PUBLIC_URL + "/team"} className="axil-btn btn-large btn-fill-primary">Our Team</Link>
                    </div>                   
                    </div>
                </div>                
                </div>
            </div>
            {/* <TeamOne /> */}
        </div>
        
    )
}

export default AboutFive;