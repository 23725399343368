import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ProcessOne from '../component/process/ProcessOne';
import AboutTwo from '../component/about/AboutTwo';
import SEO from '../common/SEO';

const MulesoftIntegration = () => {

    return (
        <>
        <SEO title="Mulesoft Integration" />
        <main className="main-wrapper">
            <HeaderOne />
            <BcrumbBannerOne 
            title="Mulesoft Integration"
            paragraph ="We offer organisations with a unified view of their systems, applications, and data sources thereby enabling them to make assured business decisions."
            paragraph2 ="We identify the systems, applications, and data sources that need to be integrated and the data exchange requirements. We build and configure the integration flows and also ensure their functionality."
            styleClass=""
            mainThumb="/images/banner/muleIntegration.svg"
            />
            <AboutTwo />
           
            <ProcessOne />
            <CtaLayoutOne />
        <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default MulesoftIntegration;