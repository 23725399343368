import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
// import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import ServiceProp from '../component/service/ServiceProp';
import ServiceData from "../data/service/ServiceMain.json";
import { slugify } from '../utils';

const allData = ServiceData;


const ServiceOne = () => {

    const mulesoftData = allData.filter(data => slugify(data.cate ? data.cate : "") === "mulesoft");
    const testingData = allData.filter(data => slugify(data.cate ? data.cate : "") === "testing");
    const webDevelopmentData = allData.filter(data => slugify(data.cate ? data.cate : "") === "web-development");
    const snaplogicData = allData.filter(data => slugify(data.cate ? data.cate : "") === "snaplogic");
    const trainingData = allData.filter(data => slugify(data.cate ? data.cate : "") === "training");



    return (
        <>
        <SEO title="Services" />
        {/* <ColorSwitcher /> */}
        <main className="main-wrapper">
            <HeaderOne />
            <BcrumbBannerOne 
                title="Best solutions for your business"
                paragraph ="Experience excellence through our flawless and consistent service process.
                "
                styleClass=""
                mainThumb="/images/banner/APIimage.svg"
            />
            <div className="service-scroll-navigation-area">

                {/* Service Nav */}
                
                <nav id="onepagenav" className="service-scroll-nav navbar onepagefixed">
                    <div className="container">
                        <ul className="nav nav-pills">
                        <li className="nav-item">
                            <a className="nav-link" href="#section1">Mulesoft</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#section2">Testing</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#section3">Web Development</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#section4">Snaplogic</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#section5">Training</a>
                        </li>
                        </ul>
                    </div>
                </nav>

                <div className="section section-padding" id="section1">
                    <div className="container">
                        <SectionTitle 
                            subtitle="Service"
                            title="Mulesoft Services"
                            description=""
                            textAlignment="heading-left"
                            textColor=""
                        
                        />
                        <div className="row">
                            <ServiceProp colSize="col-lg-4 col-md-6" serviceStyle="service-style-2" serviceData={mulesoftData}/>
                        </div>
                    </div>
                </div>

                <div className="section section-padding bg-color-light" id="section2">
                    <div className="container">
                        <SectionTitle 
                            subtitle="Service"
                            title="Performance Testing"
                            description=""
                            textAlignment="heading-left"
                            textColor=""
                        
                        />
                        <div className="row">
                            <ServiceProp colSize="col-lg-4 col-md-6" serviceStyle="service-style-2" serviceData={testingData}/>
                        </div>
                    </div>
                </div>

                <div className="section section-padding bg-color-light" id="section3">
                    <div className="container">
                        <SectionTitle 
                            subtitle="Service"
                            title="Web Development"
                            description=""
                            textAlignment="heading-left"
                            textColor=""
                        
                        />
                        <div className="row">
                            <ServiceProp colSize="col-lg-4 col-md-6" serviceStyle="service-style-2" serviceData={webDevelopmentData}/>
                        </div>
                    </div>
                </div>

                <div className="section section-padding" id="section4">
                    <div className="container">
                        <SectionTitle 
                            subtitle="Service"
                            title="Snaplogic"
                            description=""
                            textAlignment="heading-left"
                            textColor=""
                        
                        />
                        <div className="row">
                            <ServiceProp colSize="col-lg-4 col-md-6" serviceStyle="service-style-2" serviceData={snaplogicData}/>
                        </div>
                    </div>
                </div>

                <div className="section section-padding bg-color-light" id="section5">
                    <div className="container">
                        <SectionTitle 
                            subtitle="Service"
                            title="Training"
                            description=""
                            textAlignment="heading-left"
                            textColor=""
                        
                        />
                        <div className="row">
                            <ServiceProp colSize="col-lg-4 col-md-6" serviceStyle="service-style-2" serviceData={trainingData}/>
                        </div>
                    </div>
                </div>
            </div>
            <CtaLayoutOne />
            <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default ServiceOne;