import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ProcessOne from '../component/process/ProcessOne';
import AboutTwo from '../component/about/AboutTwo';
import SEO from '../common/SEO';

const ProductionSupport = () => {

    return (
        <>
        <SEO title="Production Support" />
        <main className="main-wrapper">
            <HeaderOne />
            <BcrumbBannerOne 
            title="Production Support"
            paragraph ="Our primary goal is to ensure that applications and systems are available to end-users and customers with minimal downtime and disruption by monitoring the performance and availability of applications."
            paragraph2 ="We promise the ongoing stability and availability of applications and systems in a production environment. We develop a deep understanding of the application or system being supported, as well as strong technical and communication skills to collaborate effectively."
            styleClass=""
            mainThumb="/images/banner/productionSupport.svg"
            />
            <AboutTwo />
           
            <ProcessOne />
            <CtaLayoutOne />
        <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default ProductionSupport;