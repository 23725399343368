import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';


const WidgetCategory = () => {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                // const response = await axios.get('http://localhost/wordpress/wp-json/wp/v2/categories');
                const response = await axios.get('https://edit.dwinsoft.in/wp-json/wp/v2/categories');
                const categoriesData = response.data;
                setCategories(categoriesData);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchCategories();
    }, []);

    return (
        
        <ul className="category-list list-unstyled">
        {categories.map((category) => (
            <li style={{  alignContent: 'space-between' }} key={category.id}>
                <Link to={process.env.PUBLIC_URL + `/category/${category.id}`}>{category.name} <span style={{fontWeight: 'bold'}}>({category.count})</span></Link>
                {/* Corrected placement of span */}
            </li>
        ))}
    </ul>
    );
};

export default WidgetCategory;