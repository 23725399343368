import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ProcessOne from '../component/process/ProcessOne';
import AboutTwo from '../component/about/AboutTwo';
import SEO from '../common/SEO';

const Training = () => {

    return (
        <>
        <SEO title="Training" />
        <main className="main-wrapper">
            <HeaderOne />
            <BcrumbBannerOne 
            title="Training and Certification"
            paragraph ="Our training program helps to advance your career by upskilling your technical knowledge and raising your standard. It includes lectures, interactive exercises, and assessments to reinforce learning."
            paragraph2 ="Our certification process involves a standardized way to evaluate and validate the skills and knowledge acquired through training, often through exams or practical assessments."
            styleClass=""
            mainThumb="/images/banner/certification.svg"
            />
            <AboutTwo />
           
            <ProcessOne />
            <CtaLayoutOne />
        <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default Training;